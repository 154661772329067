<template>
    <el-drawer
        v-model="addNewUserModal"
        :title="$t('Add New User')"
        append-to-body
        custom-class="add-new-user"
        size="516px"
        :z-index="1000"
    >
        <div class="drawer-content">
            <el-form
                ref="inviteUserRef"
                :model="inviteUserForm"
                :rules="inviteUserFormRules"
            >
                <el-form-item class="mb-3" prop="email">
                    <label>{{ $t("Email") }}*</label>
                    <el-input v-model="inviteUserForm.email" />
                </el-form-item>

                <div class="hint-text">
                    <img src="/media/buying/icons/info_outined.svg" width="12" alt="">
                    <span>{{ $t("User will get verification link to this email id.") }}</span>
                </div>
                <!--        <p class="fs-7 text-gray-400 text-line mt-9">-->
                <!--            <span>-->
                <!--              {{ $t("You can also add additional Info") }}-->
                <!--            </span>-->
                <!--        </p>-->

                <!--        <el-form-item prop="first_name">-->
                <!--          <label>{{ $t("First Name") }}</label>-->
                <!--          <el-input v-model="inviteUserForm.first_name" />-->
                <!--        </el-form-item>-->

                <!--        <el-form-item prop="last_name">-->
                <!--          <label>{{ $t("Last Name") }}</label>-->
                <!--          <el-input v-model="inviteUserForm.last_name" />-->
                <!--        </el-form-item>-->

                <!--        <el-form-item prop="position">-->
                <!--          <label>{{ $t("Position") }}</label>-->
                <!--          <el-input v-model="inviteUserForm.position" />-->
                <!--        </el-form-item>-->
                <el-form-item prop="role" class="mt-4">
                    <label>{{ $t("Define Access") }}</label>

                    <el-radio-group v-model="inviteUserForm.role" size="large" class="priority">
                        <el-radio-button :label="UserRoleEnum.ADMIN" :disabled="!currentUser.can(BankUserPermissionTypeEnum.INVITE_ADMIN)">
                            {{ $t("Admin") }}
                            <el-tooltip
                                v-if="!currentUser.can(BankUserPermissionTypeEnum.INVITE_ADMIN)"
                                :content="$t('Action not permitted')"
                                effect="dark"
                                placement="top"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                            </el-tooltip>
                        </el-radio-button>
                        <el-radio-button :label="UserRoleEnum.USER" :disabled="!currentUser.can(BankUserPermissionTypeEnum.INVITE_USER)">
                            {{ $t("User") }}
                            <el-tooltip
                                v-if="!currentUser.can(BankUserPermissionTypeEnum.INVITE_USER)"
                                :content="$t('Action not permitted')"
                                effect="dark"
                                placement="top"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                            </el-tooltip>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="mt-4" v-if="inviteUserForm.role !== UserRoleEnum.ADMIN && currentUser.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)">
                    <label>
                        {{ $t("Permissions Group") }}

                        <el-tooltip
                            class="box-item"
                            :append-to-body="false"
                            effect="dark"
                            placement="bottom-start"
                        >
                            <img src="/media/buying/icons/info_black.svg" alt="">
                            <template #content>
                                <div style="max-width: 250px">
                                    {{ $t('If no Permission Group is assigned, all permissions will be disabled for the user. They can be enabled later on.') }}
                                </div>
                            </template>
                        </el-tooltip>
                    </label>


                    <el-select
                        class="w-100"
                        v-model="inviteUserForm.group_id"
                        :placeholder="$t('No Permission Group')"
                    >
                        <el-option
                            v-for="item in bankDetails.permissionGroups"
                            :value="item.id"
                            :label="item.name"
                        >
                            {{ item.name }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--        <el-form-item prop="product_areas">-->
                <!--          <label>{{ $t("Product Areas") }}</label>-->
                <!--          <el-select-->
                <!--            v-model="inviteUserForm.product_areas"-->
                <!--            filterable-->
                <!--            size="large"-->
                <!--            :reserve-keyword="false"-->
                <!--            :placeholder="$t('Select Product Areas')"-->
                <!--          >-->
                <!--            <el-option-->
                <!--              v-for="(item, index) in productAreas"-->
                <!--              :key="index"-->
                <!--              :label="item"-->
                <!--              :value="item"-->
                <!--            />-->
                <!--          </el-select>-->
                <!--        </el-form-item>-->
                <!--        <el-form-item prop="country">-->
                <!--          <label>{{ $t("Country of Residence") }}</label>-->
                <!--          <el-select-->
                <!--            v-model="inviteUserForm.country"-->
                <!--            filterable-->
                <!--            size="large"-->
                <!--            :reserve-keyword="false"-->
                <!--            :placeholder="$t('Select Country')"-->
                <!--          >-->
                <!--            <el-option-->
                <!--              v-for="(item, index) in countries"-->
                <!--              :key="index"-->
                <!--              :label="item"-->
                <!--              :value="item"-->
                <!--            />-->
                <!--          </el-select>-->
                <!--        </el-form-item>-->

            </el-form>
            <div class="w-100">
                <button
                    class="btn main-btn w-100 mw-100 mt-4"
                    :data-kt-indicator="buttonIndicator"
                    :disabled="buttonIndicator === 'on'"
                    @click.prevent="inviteUser"
                >
                    <span class="indicator-label"> {{ $t("Add User") }}</span>

                    <span class="indicator-progress">
              {{ $t("pleaseWait") }}
              <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>

                </button>
            </div>
        </div>
    </el-drawer>
</template>

<script>

import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "AddUserDrawer",
    props: ["addNewUserModal", "bankDetails"],
    emits: ["handleClose"],
    data() {
        return {
            UserRoleEnum,
            BankUserPermissionTypeEnum,
            inviteUserFormRules: {
                email: [
                    {
                        required: true,
                        message: this.$t("Please input Email"),
                        trigger: "change"
                    },
                    {
                        required: true,
                        type: "email",
                        message: this.$t("Please input correct email"),
                        trigger: "change"
                    }
                ],
                role: [
                    {
                        required: true,
                        message: this.$t("Please select User Type"),
                        trigger: "change"
                    }
                ]
            },
            inviteUserForm: {
                email: "",
                // first_name: "",
                // last_name: "",
                // position: "",
                role: "",
                group_id: null,
                // product_areas: [],
                // country: ""
            },
            buttonIndicator: "off"
        };
    },
    computed: {
        productAreas() {
            return [];
        },
        countries() {
            return [];
        },
        currentUser() {
            return store.getters.currentUser
        },
    },
    methods: {
        inviteUser() {
            this.$refs.inviteUserRef.validate(valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    const payload = { ...this.inviteUserForm };
                    if (payload.role === UserRoleEnum.ADMIN) {
                        delete payload.group_id;
                    }
                    store.dispatch("inviteUserForBank", payload)
                        .then(res => {
                            if (res) {
                                this.bankDetails.addNewInvitedUsers({
                                    email: payload.email,
                                    role: payload.role,
                                    status: "pending",
                                });
                                this.$refs.inviteUserRef.resetFields();
                                this.$emit("handleClose");
                                NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                            }
                        })
                        .catch(err => {
                            console.log("error = ", err);
                        })
                        .finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        }
    },
    mounted() {
        if (this.currentUser.can(BankUserPermissionTypeEnum.INVITE_USER)) {
            this.inviteUserForm.role = UserRoleEnum.USER;
        } else if (this.currentUser.can(BankUserPermissionTypeEnum.INVITE_ADMIN)) {
            this.inviteUserForm.role = UserRoleEnum.ADMIN;
        }
    }
};
</script>

<style lang="scss">
.el-drawer.add-new-user {
    .el-drawer__body {
        height: calc(100vh - 82px);
        overflow-y: auto;
    }
    .drawer-content {
        padding: 0 15px 0 18px;

        .hint-text {
            border-radius: 4px;
            background: #FAFAFA;
            padding: 18px;
            display: flex;
            align-items: center;
            gap: 8px;
            span {
                color: #435BF4;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
        }

        .el-form-item__content {
            .el-radio-group {
                width: 100%;

                .el-radio-button {
                    margin-right: 15px;

                    .el-radio-button__inner {
                        padding: 8px 20px;
                        height: 41px;
                        background: #F4F5FE;
                        border: 1px solid #435BF4;
                        border-radius: 90px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: #435BF4;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-left: 4px;
                        }
                    }

                    &.is-active {
                        .el-radio-button__inner {
                            background: #435BF4;
                            color: #fff;
                        }
                    }

                    &.is-disabled {
                        .el-radio-button__inner {
                            background: #ededed;
                            border-color: #cacaca;
                            color: #898989;
                            opacity: 0.6;
                        }
                    }
                }
            }

            label {
                color: #595959;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
            }

            .el-input__inner {
                width: 100%;
                height: 53px;
                border-radius: 4px;
                background: #F6F6F6;
                border: none;
            }

            .el-form-item__error {
                position: revert;
                margin-top: 4px;
            }
        }
    }

    .el-drawer__header {
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding-top: 60px;

        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
            flex: revert;
        }

        button {
            display: flex;
            margin-right: 5px;
        }
    }

    &__body {
        min-height: calc(100vh - 114px);
        overflow-y: auto;
    }

    @media (max-width: 520px) {
        width: 100% !important;
    }
}
</style>
