<template>
    <el-table
        :data="teamsData"
        style="width: 100%"
        class="bank-teams-table"
        :class="className"
        header-cell-class-name="bg-light-dark py-7"
        :show-header="false"
    >
        <el-table-column
            prop="avatar"
            width="92"
            v-slot="avatar">
            <img
                width="50"
                height="50"
                class="border rounded-circle user-icon_path cursor-pointer"
                :src="avatar.row.icon_path"
                @click="onRowClick(avatar.row)"
                alt=""
            >
        </el-table-column>
        <el-table-column
            prop="name"
            width="300"
            v-slot="name">
            <p
                class="bank-teams-table--name"
                @click="onRowClick(name.row)"
            >
                <span>{{ name.row.full_name }}</span>
                <span>{{ showRole ? $t("Admin") : "" }}</span>
            </p>
        </el-table-column>
        <el-table-column
            prop="jobTitle"
            width="300"
            v-slot="jobTitle">
            <p class="bank-teams-table--bold">{{ jobTitle.row.job_title }}</p>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="country"
            v-slot="countries">
            <span class="bank-teams-table--bold">{{ countries.row.country }}</span>
        </el-table-column>
        <el-table-column
            width="150"
            v-slot="name">
            <div class="me-0 d-flex justify-content-end pe-2">
                <a
                    @click.prevent="onRowClick(name.row)"
                    href=""
                    class="bank-teams-table--link me-0"
                    v-if="authUser.id === name.row.id"
                >
                    {{ $t("View My Profile") }}
                </a>
                <template v-else>
                    <el-dropdown trigger="click" class="mr-2">
                        <span class="el-dropdown-link bank-teams-table--link-icon">
                            <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="onRowClick(name.row)">
                                    {{ $t("View User") }}
                                </el-dropdown-item>
                                <el-dropdown-item v-if="hasAccessToEdit" @click="handleEditUser(name.row)">
                                    {{ $t("Edit User") }}
                                </el-dropdown-item>
                                <el-dropdown-item @click="handleUserRoleChange(name.row)" v-if="hasAccessToPromoteDemote">
                                    {{ showRole ? $t("Change to User") : $t("Make Admin") }}
                                </el-dropdown-item>
                                <el-dropdown-item @click="handleRemoveUser(name.row)" v-if="hasAccessToDelete">
                                    {{ $t(("Delete User")) }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </div>
        </el-table-column>
    </el-table>
</template>
<script>
import BankTeamUserOperationEnum from "@/store/enums/bank/BankTeamUserOperationEnum";
import { UserModel } from "@/store/models/UserModel";

export default {
    name: "BankTeamsTable",

    props: {
        teamsData: Array,
        authUser: [Object, UserModel],
        showRole: Boolean,
        className: String,
        hasAccessToDelete: Boolean,
        hasAccessToPromoteDemote: Boolean,
        hasAccessToEdit: Boolean,
    },

    emits: ["handle-view", "handle-view-profile", "handleUserRoleChange", "handle-edit"],

    methods: {
        onRowClick(row) {
            if (this.authUser.id === row.id) {
                this.$emit("handle-view-profile", row.id);
            } else {
                this.$emit("handle-view", row.id);
            }
        },
        handleUserRoleChange(row) {
            this.$emit("handleUserRoleChange", {
                data: {
                    user_id: row.id,
                    operation: row.is_admin ? BankTeamUserOperationEnum.DEMOTE : BankTeamUserOperationEnum.PROMOTE
                },
                is_admin: row.is_admin
            });
        },
        handleRemoveUser(row) {
            this.$emit("handleUserRoleChange", {
                data: {
                    user_id: row.id,
                    operation: BankTeamUserOperationEnum.REMOVE
                },
                is_admin: row.is_admin
            });
        },
        handleEditUser(row) {
            if (this.hasAccessToEdit) {
                this.$emit("handle-edit", row.id);
            }
        }
    }
};
</script>
<style lang="scss">
.bank-teams-table {
    &--name {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        cursor: pointer;

        span:first-child {
            font-weight: 500;
            font-size: 18px;
            line-height: 158.02%;
            color: #2B2B2B;
        }

        span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #A7A7A7;
        }
    }

    &--bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #8B8B8B;
        margin-bottom: 0;
    }

    &--link {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #435BF4;
        margin-right: 28px;

        &-icon i {
            color: #435BF4;
        }
    }

    &:before {
        display: none;
    }

    .el-table_1_column_1.el-table__cell {
        border-bottom: none;
    }

    .el-table__row {
        &:last-child {
            .el-table__cell {
                border-bottom: none;
            }
        }
    }

    .user-icon_path {
        object-fit: cover;
        object-position: top;
    }

    .width-max-content {
        width: max-content;
    }
}
</style>
