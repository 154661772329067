<template>
  <div class="card mb-5 mb-xxl-8 py-0 bank-team-page">
    <div class="card-body pt-6 px-0 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
        <div class="me-7 mb-4">
          <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            <img :src="bankData.icon_path" alt="image" />
          </div>
        </div>

        <div class="flex-grow-1">
          <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{bankData.name}}</a>
              </div>

              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  <span class="svg-icon svg-icon-4 me-3">
                     <inline-svg src="/media/buying/icons/date-icon.svg" />
                  </span>
                  <span>Based on last 6 months</span>
                </a>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="me-0">
                <button class="btn main-btn me-3 px-10" @click="addNewUserModal = true">
                  {{ $t("Add New User") }}
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap flex-stack">
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <div class="d-flex flex-wrap user-counter">
                <template v-for="(item, index) in counter">
                  <div class="border border-gray-300 rounded min-w-175px py-3 px-4 me-6 mb-3">
                    <div class="d-flex flex-column align-items-center">
                      <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                      <div class="fs-1 fw-boldest">{{ item.value }}</div>
                    </div>
                  </div>
                  <p class="border-start border-gray-300 me-6" v-if="index === 0"></p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddUserDrawer v-model="addNewUserModal" @handleClose="addNewUserModal=false" />
  </div>

  <div class="card p-6 mb-8">
    <div class="card-header cursor-pointer px-0 pt-2 pb-6 mb-6">
      <div class="card-title m-0 mb-lg-0 mb-5 me-3 ms-5">
        <h3 class="fw-bolder m-0 ">{{ $t("Admins") }}</h3>
        <span class="text-with-bg ms-3">{{ adminsData.length }}</span>
      </div>
    </div>
    <BankTeamsTable
      :show-role="true"
      :teams-data="adminsData"
      :auth-user="userInfo"
      :user-role-enum="UserRoleEnum"
      @handle-view="viewUser"
      @handle-view-profile="viewAuthUserProfile"
    />
  </div>

  <div class="card p-6 bank-team-page">
    <div class="card-header cursor-pointer px-0 pt-2 pb-6 mb-6">
      <div class="card-title m-0 mb-lg-0 mb-5 me-3 ms-5">
        <h3 class="fw-bolder m-0 ">{{ $t("Users") }}</h3>
        <span class="text-with-bg ms-3">{{ adminsData.length }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="add-new-user" @click="addNewUserModal = true">
          {{ $t('Add New User') }}
        </div>
        <div class="search-user">
          <img src="/media/buying/icons/search.svg" alt="">
          <el-input
            v-model="search"
            class="m-2 search-user"
            size="large"
            :placeholder="$t('Search user')"
          />
        </div>
      </div>
    </div>
    <BankTeamsTable
      :teams-data="adminsData"
      :show-role="false"
      :auth-user="userInfo"
      :user-role-enum="UserRoleEnum"
      @handle-view="viewUser"
      @handle-view-profile="viewAuthUserProfile"
      class-name="mb-8"
    />
    <button class="btn w-100 py-6 btn-light-primary menage-btn">
      See All (54)
    </button>
  </div>
</template>

<script>
import store from "@/store";
import {Bottom} from "@element-plus/icons-vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import BankTeamsTable from "@/buying-teams/pages/bank/teams/components/BankTeamsTable";
import {UserRoleEnum} from "@/store/enums/UserRoleEnum";
import {Constants} from "@/core/config/constants";
import AddUserDrawer from "@/buying-teams/pages/bank/teams/components/AddUserDrawer";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";

export default {
  name: "AllUsers",
  components: {AddUserDrawer, BankTeamsTable, Bottom },
  data() {
    return {
      UserRoleEnum,
      counter: [
        {
          label: 'Average Score',
          value: 0
        },
        {
          label: 'Average Importance',
          value: 0
        },
        {
          label: 'Average Gap',
          value: 0
        }
      ],
      addNewUserModal: false,
      search: ''
    }
  },
  computed: {
    bankData() {
      return store.getters.bank;
    },
    userInfo() {
      return store.getters.currentUser
    },
    adminsData() {
      return [
        {
          id : 1,
          icon_path: Constants.USER_TMP_LOGO,
          first_name: 'Daniel',
          last_name: 'Lapp',
          role: 'Admin',
          job_title: 'CTO',
          countries: ['Argentina']
        },
        {
          id : 2,
          icon_path: Constants.USER_TMP_LOGO,
          first_name: 'Jo',
          last_name: 'Christopher',
          role: 'Admin',
          job_title: 'Senior Product Designer ',
          countries: ['Argentina']
        }
      ]
    }
  },
  methods: {
    viewUser(id) {
      this.$router.push("/bank/teams/user/" + id);
    },
    viewAuthUserProfile() {
      redirectToRouteWithName('bank-user-setting-overview');
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs({title: `${ this.$t('hi')} ${ this.userInfo.first_name },  <span>${ this.$t('welcomeBack') }</span>`});
  },
}
</script>

<style lang="scss">
.bank-team-page {
  .card-header {
    min-height: auto;
  }
  .add-new-user {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #435BF4;
    margin-right: 27px;
  }
  .search-user {
    margin: 0 !important;
    width: 300px;
    position: relative;
    > img {
      position: absolute;
      z-index: 1;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      border-radius: 50px;
      padding-left: 47px;
    }
  }
  .search-user {
    .el-input {
      width: 100%;
    }
  }
}
</style>
